import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { goToLandmark } from '../../utils'

import './EntryForm.sass'

const EntryForm = () => {
  const recaptchaAction = 'contact_form'
  const formReference = 'ds24CompEnter'
  const MAX_TOKEN_TRIES = 0 // 0 = no limit
  const [tokenTries, setTokenTries] = useState(0)

  const { executeRecaptcha } = useGoogleReCaptcha()
  const {
    reset,
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: {
      errors,
      isSubmitting,
      isSubmitted,
      isSubmitSuccessful,
      submitCount,
    },
  } = useForm()

  const getReCaptchaToken = async () => {
    if (!executeRecaptcha) {
      // TODO: Wait and retry?
      throw new Error('Unable to retrieve token yet.', {
        cause: { ref: 'AZJcxV' },
      })
    }

    setTokenTries(tokenTries + 1)
    const result = await executeRecaptcha(recaptchaAction)
    return result
  }

  const onFormSubmit = async (data) => {
    try {
      const captcha = await getReCaptchaToken()

      if (!captcha) {
        throw new Error('Unable to retrieve token', {
          cause: {
            ref: 'WnZcll',
            related: { captcha },
          },
        })
      }

      await fetch(`${process.env.ARIMA_BASE_URL}/website/campaign-ds24/enter`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          formName: formReference,
          ...data,
          token: captcha || '',
          tokenTries: tokenTries + 1,
          submitCount: submitCount + 1,
        }), // body data type must match "Content-Type" header
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((body) => {
              const text = body.body || JSON.parse(body)
              throw new Error('Unable to complete sending', {
                cause: {
                  ref: 'SsGm5x',
                  message: text.message || text,
                  code: text.code,
                  related: response,
                },
              })
            })
          } else {
            return response.json()
          }
        })
        .then(() => {
          // Success, reset form
          reset()
        })
        .catch((err) => {
          if (err instanceof Error) {
            throw err
          } else {
            throw new Error(
              'An error occurred while submitting the entry form',
              {
                cause: {
                  ref: 'sVlHja',
                  related: err,
                },
              }
            )
          }
        })
    } catch (err) {
      if (err instanceof Error) {
        handleSubmitResponseError(err)
      } else {
        handleSubmitResponseError({
          ref: 'JK5mXm',
          message: 'An unexpected error has occurred',
          related: err,
        })
      }
    }
  }

  const onTryAgain = () => {
    clearErrors('submit')
    if (
      MAX_TOKEN_TRIES === 0 ||
      (MAX_TOKEN_TRIES > 0 && tokenTries < MAX_TOKEN_TRIES)
    ) {
      handleSubmit(onFormSubmit)()
    } else {
      handleSubmitResponseError({
        ref: 'IyFFp5',
        message: 'Maximum token tries reached',
        related: { tokenTries, MAX_TOKEN_TRIES },
      })
    }
  }

  const handleSubmitResponseError = (err) => {
    // handleSubmitResponseError can take an Error object or a dev constructed Object with the following params:
    //   @param {Error} (from Standard built-in object)
    //     @param {string} name - type of Error thrown (always "Error")
    //     @param {string} message - a message reference of the error
    //     @param {string} cause - a message reference of the error
    //   @param {Object}
    //     @param {string} ref - a unique code to find the source of error
    //     @param {string} message - a message reference of the error
    //     @param {any} related - any information related to the error, ie response, additional info.

    let displayMessage =
      err?.cause?.message || err?.message || 'An unexpected error has occured'

    if (process.env.NODE_ENV !== 'production')
      if (err instanceof Error) {
        console.error('ERROR', err.name + ': ' + err.message, err.cause)
      } else {
        console.error('OTHER', err)
      }

    setError('submit', {
      type: 'manual',
      message: `${displayMessage}`,
    })
  }

  return (
    <section>
      <h2 className="sectionHeading v--mainSection">Entry form</h2>
      <form
        name={formReference}
        method="post"
        onSubmit={handleSubmit(onFormSubmit)}
        action="/submitted"
        className="mb-5 pb-5"
      >
        <div className="row">
          <fieldset className="col-sm mb-3">
            <legend className="h3 mb-5 font-weight-bold">
              Your details
              <br className="d-none d-sm-block" />
              <small> (To help us verify it's you)</small>
            </legend>
            <div className="form-group">
              <label htmlFor="fullname">Full name</label>
              <input
                type="text"
                className={`form-control ${
                  errors.fullname ? 'border-danger' : ''
                }`}
                {...register('fullname', {
                  required: 'Full name is required.',
                })}
                required
              />
              <small className={`form-text text-danger`}>
                {errors.fullname?.message}
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="company">Company (optional)</label>
              <input
                type="text"
                className="form-control"
                {...register('company')}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                className={`form-control ${
                  errors.email ? 'border-danger' : ''
                }`}
                {...register('email', {
                  required: 'Email address is required.',
                })}
                aria-describedby="emailHelp"
                required
              />
              <small id="emailHelp" className={`form-text`}>
                (We will use this email to confirm your details if you win.)
              </small>
              <small className={`form-text text-danger`}>
                {errors.email?.message}
              </small>
            </div>
            <div className="form-group mt-5">
              <label className="d-flex align-items-start mb-4">
                <input
                  {...register('subscribed')}
                  type="checkbox"
                  className="mr-3 mt-2 flex-shrink-0"
                  style={{ width: 20 }}
                />
                <div className="w-100">
                  Subscribe to product updates from Ironstar.
                  <div className="small">
                    (No more than once a month. We will never sell or share your
                    details. Unsubscribe at any time.)
                  </div>
                </div>
              </label>
              <label className="d-flex align-items-start">
                <input
                  {...register('agree', {
                    required:
                      'You must agree to the terms & conditions to enter.',
                  })}
                  type="checkbox"
                  className="mr-3 mt-2 flex-shrink-0"
                  style={{ width: 20 }}
                />
                <div className="w-100">
                  I have read and agree to the{' '}
                  <a
                    href="#terms"
                    onClick={(evt) => {
                      evt.preventDefault()
                      goToLandmark('terms', 0)
                    }}
                  >
                    terms &amp; conditions
                  </a>
                  .
                </div>
              </label>
              <small className={`form-text text-danger`}>
                {errors.agree?.message}
              </small>
            </div>
          </fieldset>
        </div>
        <div className="">
          <div className="form-group text-right mb-3">
            <button
              className={`btn btn-primary ml-3 my-2 ${
                isSubmitting ? 's--sending' : ''
              }`}
              disabled={isSubmitting}
              type={isSubmitted && errors.submit ? 'button' : 'submit'}
              onClick={isSubmitted && errors.submit ? onTryAgain : () => {}}
            >
              {(isSubmitting && 'Sending...') ||
                (isSubmitted && errors.submit && 'Try Again') ||
                'Submit Entry'}
            </button>
          </div>
          {errors && Object.entries(errors).length > 0 && (
            <div className="alert alert-danger" role="alert">
              {(errors.submit?.message && (
                <>
                  <p>
                    Your competition entry hasn't been submitted for the
                    following reason:
                  </p>
                  <p className="font-weight-bold">{errors.submit.message}</p>
                  <p>
                    If you need help, stop by the Ironstar booth for assistance.
                  </p>
                </>
              )) || (
                <>
                  <p>
                    There are errors in the entry form. Please correct the
                    errors and try again.
                  </p>
                  <p>
                    If the issue persists, stop by the Ironstar booth for
                    assistance.
                  </p>
                </>
              )}
            </div>
          )}
          {isSubmitted &&
            !isSubmitting &&
            (!errors || Object.entries(errors)?.length === 0) &&
            isSubmitSuccessful && (
              <div className="alert alert-success" role="alert">
                <p>Your entry was successfully submitted.</p>
                <p>
                  Find out if you are a winner at the draw on{' '}
                  <strong>Day 2 at 10:40am</strong> at the Ironstar booth.
                </p>
              </div>
            )}
        </div>
      </form>
    </section>
  )
}

export default EntryForm
