import React from 'react'
import CompBtns from './CompBtns'

import './CompMasthead.sass'

const CompMasthead = () => {
  return (
    <div className="titleWrapper">
      <h1 className="titleMain">Deck Yourself Out!</h1>
      <div className="titleSub">
        To celebrate one of the best DrupalSouth events yet,{' '}
        <br className="hide-xs" />
        we're giving away some of the best tech gadgets!
      </div>
      <CompBtns />
    </div>
  )
}

export default CompMasthead
