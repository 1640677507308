import React from 'react'

import { goToLandmark } from '../../utils'
import './Btns.sass'

const CompBtns = () => {
  return (
    <div className="navCompBtns">
      <a
        className="btn btn-link"
        href="#howto"
        onClick={(evt) => {
          evt.preventDefault()
          goToLandmark('howto')
        }}
      >
        How to play
      </a>
      <a
        className="btn btn-outline-primary"
        href="#enter"
        onClick={(evt) => {
          evt.preventDefault()
          goToLandmark('enter')
        }}
      >
        Entry form
      </a>
      <a
        className="btn btn-link"
        href="#terms"
        onClick={(evt) => {
          evt.preventDefault()
          goToLandmark('terms')
        }}
      >
        Terms &amp; Conditions
      </a>
    </div>
  )
}

export default CompBtns
