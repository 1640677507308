import React from 'react'

import './ActivityDetails.sass'

const WhatIs = () => {
  return (
    <section className="activitySection bg-dark text-light">
      <div className="container">
        <h2 className="sectionHeading v--mainSection">
          More at DrupalSouth 2024
        </h2>

        <div className="activityBlocks">
          <article id="awards" name="awards" className="border border-primary">
            <div>
              <h3 className="heading">Celebrating the best of Drupal</h3>
              <p>
                Ironstar is proud to sponsor this year's DrupalSouth Splash
                Awards, an evening dedicated to glamour and recognising the
                remarkable achievements within the Drupal community.
              </p>
              <p>
                Join us (in optional cocktail attire) to celebrate the
                creativity, innovation, and hard work that have pushed the
                boundaries of what Drupal can achieve.
              </p>
            </div>
            <p className="activityCTA">
              The Splash Awards will be held on{' '}
              <strong>Wednesday at 4pm</strong>.
            </p>
          </article>

          <article id="cubes" name="cubes" className="bg-light text-dark">
            <div>
              <h3 className="heading">Ready for the Cube Challenge?</h3>
              <p>
                Test your skills with the Ironstar Puzzle Cube available at our
                booth. Not your average cube, our puzzle is designed to test
                even the most experienced cubers.
              </p>
              <div className="row">
                <div className="col-lg-8">
                  <p>
                    <strong className="text-primary">
                      New to cubing? No worries!
                    </strong>{' '}
                    We also have a beginner-friendly version, and our Ironstar
                    team is on standby to teach you the ropes and assist with
                    tips!
                  </p>
                </div>
                <div className="col-lg-4 text-center">
                  <img
                    src="/assets/campaigns/ds24/cubes.png"
                    alt="Ironstar Puzzle Cubes"
                    className="image"
                  />
                </div>
              </div>
            </div>
            <div className="activityCTA">
              The <strong>first 5</strong> to solve the challenge cube get a
              small prize!
            </div>
          </article>

          <article
            id="party"
            name="party"
            className="bg-dark bg-with-image"
            style={{
              backgroundImage:
                "url('/assets/campaigns/ds24/unsplash-bTN-zKFy9uA.jpg')",
            }}
          >
            <div className="text-shadow">
              <h3 className="heading">Cocktails on us at the After Party!</h3>
              <p>
                Keep the DrupalSouth vibe going at Ironstar's Cocktail After
                Party at Soultrap Bar. Nibbles will be provided. Simply show
                your conference pass for entry!
              </p>
            </div>
            <div className="activityCTA">
              The party starts on <strong>Thursday at 4pm</strong> at Soultrap
              Bar.
            </div>
          </article>

          <article id="stickers" name="stickers" className="border">
            <div>
              <h3 className="heading">What are these Sticker Characters?</h3>
              <p>
                The Ironstar platform has orchestrated hundreds of thousands of
                builds, deployments, backups and syncs. How do we keep track?
                With a random name generator that makes these easier to
                identify, and a bit more fun.
              </p>
              <div className="d-flex flex-wrap align-items-start">
                <div className="stickerTeasers">
                  <div className="v--sticker no-bg">
                    <img
                      src="/assets/campaigns/ds24/sticker_kiwi.png"
                      alt="Astonishingly Educated Kiwi Character Sticker"
                    />
                  </div>
                  <div className="v--sticker">
                    <svg viewBox="0 0 500 500">
                      <use href="/assets/campaigns/ds24/sticker_shapes.svg#square" />
                    </svg>
                  </div>
                  <div className="v--sticker">
                    <svg viewBox="0 0 500 500">
                      <use href="/assets/campaigns/ds24/sticker_shapes.svg#hexagon" />
                    </svg>
                  </div>
                </div>
              </div>
              <p>
                We've transformed our favourite name combinations into
                collectible stickers to share with our friends at DrupalSouth.
                Dive into your swag bag, stop by our booth, or just ask someone
                in a blue Ironstar shirt.
              </p>
            </div>
            <p className="activityCTA">
              There are <strong>3 new stickers</strong> at DrupalSouth 2024 to
              collect!
            </p>
          </article>
        </div>
      </div>
    </section>
  )
}

export default WhatIs
