import $ from 'jquery'

function offset(el) {
  var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

function activateWayPoint(elementId) {
  if (elementId === 'link-we-do' && !$('#dynamic-pics').hasClass('activated')) {
    setTimeout(() => $('#dynamic-pics').addClass('activated'), 1000)
  }

  return Array.from(
    document.getElementsByClassName('sticky-subnav-item')
  ).forEach(element => {
    if (element.id !== elementId) {
      element.className = 'sticky-subnav-item'
    } else {
      element.className = 'sticky-subnav-item active'
    }
  })
}

function goToLandmark(targetName, additional = 0) {
  const elements = document.getElementsByName(targetName)
  const element = document.getElementById(targetName)

  let targetEl

  if (elements.length !== 0) {
    targetEl = elements[0]
  } else {
    if (element) {
      targetEl = element
    } else return
  }

  $('html, body').animate({ scrollTop: offset(targetEl).top - additional }, 500)
  // window.scrollTo({
  //   top: offset(targetEl).top - additional,
  //   behavior: "smooth"
  // })
}

export { activateWayPoint, goToLandmark }
