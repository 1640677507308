import { Link } from 'gatsby'
import React from 'react'

import './Terms.sass'

const Terms = () => {
  return (
    <section>
      <h2 className="sectionHeading pb-0 mb-0">Terms and conditions</h2>
      <div className="termsContent small">
        <div className="text-muted mt-1 mb-4">(Click to expand/close)</div>

        <details className="border rounded p-4">
          <summary>
            <h3 className="h6 mb-5 d-inline">
              DrupalSouth 2024 "Deck yourself out" Competition
            </h3>
          </summary>
          <h4 className="h4">General</h4>
          <ol>
            <li>
              The promoter is Ironstar Hosting Services Pty Ltd, ACN 628 724
              578, “<strong>Ironstar</strong>”.
            </li>
            <li>
              The promotional period starts from 20th March 2024 at 8am AEDT and
              ends on 21st March 2024 at 10:40am AEDT.
            </li>
            <li>
              This promotion (the “<strong>competition</strong>”) is conducted
              as a <em>trade promotion</em> under NSW Fair Trading in accordance
              with the{' '}
              <a
                href="https://legislation.nsw.gov.au/view/html/inforce/current/act-2018-060"
                target={'_blank'}
                rel="nofollow noreferrer noopener"
              >
                Community Gaming Act 2018
              </a>{' '}
              and{' '}
              <a
                href="https://legislation.nsw.gov.au/view/html/inforce/current/sl-2020-0304"
                target={'_blank'}
                rel="nofollow noreferrer noopener"
              >
                Community Gaming Regulation 2020
              </a>
              .
            </li>
            <li>
              Ironstar reserves the right to cancel or amend the competition and
              these terms and conditions without notice in the event of a
              catastrophe, war, civil disturbance, act of God or any actual or
              anticipated breach of any applicable law or regulation or any
              other event outside of Ironstar's control.
            </li>
            <li>
              Any changes to the competition will be notified to entrants as
              soon as possible by Ironstar using the email address provided to
              Ironstar when entering the competition.
            </li>
            <li>
              Ironstar is not responsible for inaccurate competition or prize
              details supplied to any entrant by any third party connected with
              this competition.
            </li>
            <li>
              Ironstar's decisions in respect of all matters to do with the
              competition will be final and no dispute or correspondence will be
              entered into.
            </li>
            <li>
              By entering this competition, you agree to be bound by these terms
              and conditions. If a winning entrant does not accept the Terms and
              Conditions the prize will be forfeited.
            </li>
            <li>
              This promotion is in no way sponsored, endorsed or administered
              by, or associated with DrupalSouth.
            </li>
          </ol>

          <h4 className="h4 mb-4">Eligibility</h4>
          <ol>
            <li>
              Entry is open to any individual registered attendee of the
              DrupalSouth 2024 Conference (“
              <strong>you</strong>”, the “<strong>entrant</strong>”)
            </li>
            <li>
              Employees of Ironstar, and their Immediate Families, are not
              permitted to enter the competition and are ineligible to win any
              prize.
            </li>
            <li>
              No purchase necessary to enter. A purchase or payment of any kind
              will not increase your chances of winning a prize.
            </li>
          </ol>
          <h4 className="h4 mb-4">How to Enter</h4>
          <ol>
            <li>
              You may only enter the competition once and may only win a maximum
              of one prize.
              <ol>
                <li>
                  Ironstar reserves the right to disqualify any entrant from the
                  competition without notice if they believe the entrant has
                  cheated, entered multiple times, used false personal
                  information, or for any other reason that may undermine the
                  integrity or fairness of the competition.
                </li>
              </ol>
            </li>
            <li>
              To enter the competition, the entrant must:
              <ol>
                <li>
                  Register your full name and email address via the entry
                  website located at{' '}
                  <Link to="/ds24">https://ironstar.io/ds24</Link> during the
                  promotion period.
                </li>
                <li>
                  Attend the live prize drawing (the “<strong>Drawing</strong>”)
                  in person at the Ironstar booth at DrupalSouth 2024 on
                  Thursday, 21st March 2024 at 10:30 AM (AEDT).
                </li>
              </ol>
            </li>
          </ol>
          <h4 className="h4 mb-4">Prize Drawing & Winning Entries</h4>
          <ol>
            <li>
              Entry is for the opportunity to win one of 3 prizes;
              <ul>
                <li>
                  Valve Steam Deck OLED 512GB International version (ARV:
                  AUD$1,299)
                </li>
                <li>
                  Bose QuietComfort Ultra noise cancelling headphones (ARV:
                  AUD$649)
                </li>
                <li>Kobo Nia eReader (ARV: AUD$179)</li>
              </ul>
            </li>
            <li>
              Winners will be drawn randomly by Ironstar from the valid entry
              pool at the Drawing and notified in person. A total of 3 winners
              will be drawn.
            </li>
            <li>
              If your name is drawn as a prize winner, you must be present at
              the Drawing to collect your prize. If you are not present when
              your name is called, your prize is forfeit and a new name will be
              drawn.
            </li>
            <li>
              Winners may not choose any individual prize but instead will be
              awarded the prize indicated at the time of drawing. The prize
              awarded will be based on the order winners are drawn (starting
              from lowest value prize).
            </li>
            <li>
              Winning entries cannot be returned, refunded or exchanged. No cash
              alternative to the prizes will be offered. The prizes are not
              transferable. Prizes are subject to availability and Ironstar
              reserves the right to substitute any prize with another of
              equivalent value without giving notice.
            </li>
            <li>
              Warranty and service of any prize is at the sole discretion and
              control of the manufacturer of the prize. Ironstar will not
              provide any warranty or replacement services for defective or
              malfunctioning prizes.
            </li>
          </ol>

          <h4 className="h4 mb-4">Privacy</h4>
          <ol>
            <li>
              Personal information collected by Ironstar is subject to the{' '}
              <Link to="/privacy">Ironstar Privacy Policy</Link>.
            </li>
            <li>
              You are providing information to Ironstar and not to any other
              party.
            </li>
            <li>
              You understand and agree that your full name will be used to
              identify the entrant at the time of the drawing.
            </li>
            <li>
              Marketing communication will only be sent to individuals who
              explicitly opt-in to receive such communications from Ironstar
              from time to time.
            </li>
            <li>
              You may at your sole discretion grant Ironstar permission to
              photograph you and your prize at the event and to publish those
              photos for Ironstar's promotional purposes. Such photographs will
              remain the property of Ironstar.
            </li>
          </ol>
        </details>

        <details className="border rounded p-4 mt-4">
          <summary>
            <h3 className="h6 mb-5 d-inline">
              DrupalSouth 2024 "Cube Challenge"
            </h3>
          </summary>
          <h4 className="h4">General</h4>
          <ol>
            <li>
              The promoter is Ironstar Hosting Services Pty Ltd, ACN 628 724
              578, “<strong>Ironstar</strong>”.
            </li>
            <li>
              The promotional period starts from 20th March 2024 at 8am AEDT and
              ends on 21st March 2024 at 4:00pm AEDT or until the maximum number
              of winners has been declared.
            </li>
            <li>
              This promotion (the “<strong>competition</strong>”, the "
              <strong>challenge</strong>") is conducted as a{' '}
              <em>trade promotion</em> under NSW Fair Trading in accordance with
              the{' '}
              <a
                href="https://legislation.nsw.gov.au/view/html/inforce/current/act-2018-060"
                target={'_blank'}
                rel="nofollow noreferrer noopener"
              >
                Community Gaming Act 2018
              </a>{' '}
              and{' '}
              <a
                href="https://legislation.nsw.gov.au/view/html/inforce/current/sl-2020-0304"
                target={'_blank'}
                rel="nofollow noreferrer noopener"
              >
                Community Gaming Regulation 2020
              </a>
              .
            </li>
            <li>
              Ironstar reserves the right to cancel or amend the competition and
              these terms and conditions without notice in the event of a
              catastrophe, war, civil disturbance, act of God or any actual or
              anticipated breach of any applicable law or regulation or any
              other event outside of Ironstar's control.
            </li>
            <li>
              Any changes to the competition will be notified to entrants as
              soon as possible by Ironstar.
            </li>
            <li>
              Ironstar is not responsible for inaccurate competition or prize
              details supplied to any entrant by any third party connected with
              this competition.
            </li>
            <li>
              Ironstar's decisions in respect of all matters to do with the
              competition will be final and no dispute or correspondence will be
              entered into.
            </li>
            <li>
              By entering this competition, you agree to be bound by these terms
              and conditions. If a winning entrant does not accept the Terms and
              Conditions the prize will be forfeited.
            </li>
            <li>
              This promotion is in no way sponsored, endorsed or administered
              by, or associated with DrupalSouth.
            </li>
          </ol>

          <h4 className="h4 mb-4">Eligibility</h4>
          <ol>
            <li>
              Entry is open to any individual registered attendee of the
              DrupalSouth 2024 Conference (“
              <strong>you</strong>”, the “<strong>entrant</strong>”, the "
              <strong>chellenger</strong>")
            </li>
            <li>
              Employees of Ironstar, and their Immediate Families, are not
              permitted to enter the competition and are ineligible to win any
              prize.
            </li>
            <li>
              No purchase necessary to enter. A purchase or payment of any kind
              will not increase your chances of winning a prize.
            </li>
          </ol>
          <h4 className="h4 mb-4">How to Enter</h4>
          <ol>
            <li>
              You may enter the challenge an unlimited number of times. You may
              only win a maximum of one prize.
              <ol>
                <li>
                  Ironstar reserves the right to disqualify any entrant from the
                  competition without notice if they believe the entrant has
                  cheated, entered multiple times, used false personal
                  information, or for any other reason that may undermine the
                  integrity or fairness of the competition.
                </li>
              </ol>
            </li>
            <li>
              To enter the challenge, the entrant must:
              <ol>
                <li>
                  Register your intent to participate in the challenge to an
                  Ironstar team member at the Ironstar booth.
                </li>
                <li>
                  Receive a shuffled cube from an Ironstar team member, and use
                  this cube for the challenge attempt.
                </li>
                <li>
                  Complete the cube in the presense of an Ironstar team member
                  at the Ironstar booth.
                </li>
              </ol>
            </li>
            <li>
              If an entrant leaves the presence of the Ironstar team member
              during their challenge attempt, the attempt is unsuccessful and
              the entry is forfeit. The entrant may choose to try again.
            </li>
          </ol>
          <h4 className="h4 mb-4">Winning Entries & Prize Details</h4>
          <ol>
            <li>
              The first 5 entrants to complete the cube under the conditions of
              the challenge will receive a prize approximately valued at AUD$20
              (ARV).
            </li>
            <li>
              Entrants who complete the cube after the first 5 winners can still
              take the challenge and appear in the timed leaderboards.
            </li>
            <li>
              Winners will be given the prize immediately upon completing the
              challenge as verified by an Ironstar team member.
            </li>
            <li>
              Prizes cannot be returned, refunded or exchanged. No cash
              alternative to the prizes will be offered. The prizes are not
              transferable. Prizes are subject to availability and Ironstar
              reserves the right to substitute any prize with another of
              equivalent value without giving notice.
            </li>
            <li>
              Warranty and service of any prize is at the sole discretion and
              control of the manufacturer of the prize. Ironstar will not
              provide any warranty or replacement services for defective or
              malfunctioning prizes.
            </li>
          </ol>

          <h4 className="h4 mb-4">Privacy</h4>
          <ol>
            <li>
              Personal information collected by Ironstar is subject to the{' '}
              <Link to="/privacy">Ironstar Privacy Policy</Link>.
            </li>
            <li>
              You are providing information to Ironstar and not to any other
              party.
            </li>
            <li>
              You understand and agree that you will be timed during your entry
              attempt and the time may be published as part of the challenge
              results. You may choose at your discretion to keep your name
              anonymous.
            </li>
            <li>
              You may at your sole discretion grant Ironstar permission to
              photograph you and your prize at the event and to publish those
              photos for Ironstar's promotional purposes. Such photographs will
              remain the property of Ironstar.
            </li>
          </ol>
        </details>
      </div>
    </section>
  )
}

export default Terms
