import React from 'react'

import { goToLandmark } from '../../utils'

const NavBtns = () => {
  return (
    <div className="container-fluid d-flex justify-content-center mb-5 flex-wrap navMain">
      <a
        className="btn btn-primary btn-r-large"
        href="#competition"
        onClick={(evt) => {
          evt.preventDefault()
          goToLandmark('competition')
        }}
      >
        Competition
      </a>
      <a
        className="btn btn-outline-secondary btn-r-large"
        href="#awards"
        onClick={(evt) => {
          evt.preventDefault()
          goToLandmark('awards')
        }}
      >
        Splash Awards
      </a>
      <a
        className="btn btn-outline-secondary btn-r-large"
        href="#cubes"
        onClick={(evt) => {
          evt.preventDefault()
          goToLandmark('cubes')
        }}
      >
        Puzzle Cubes
      </a>
      <a
        className="btn btn-outline-secondary btn-r-large"
        href="#party"
        onClick={(evt) => {
          evt.preventDefault()
          goToLandmark('party')
        }}
      >
        After Party
      </a>
      <a
        className="btn btn-outline-secondary btn-r-large"
        href="#stickers"
        onClick={(evt) => {
          evt.preventDefault()
          goToLandmark('stickers')
        }}
      >
        Stickers
      </a>
    </div>
  )
}

export default NavBtns
