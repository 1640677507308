import React from 'react'

import './Prizes.sass'

const Prizes = () => {
  return (
    <div className="prizesWrapper container mb-5">
      <div className="prize v--1st">
        <div className="image">
          <img src="/assets/campaigns/ds24/prize_1st.png" alt="" />
        </div>
        <div className="content">
          <small className="rank">1st</small>
          <div className="name">
            <strong>Valve Steam Deck</strong>
            <br />
            OLED 512GB
            <small>International version</small>
          </div>
        </div>
        <div className="value">valued at $1,299</div>
      </div>
      <div className="prize v--2nd">
        <div className="image">
          <img src="/assets/campaigns/ds24/prize_2nd.png" alt="" />
        </div>
        <div className="content">
          <small className="rank">2nd</small>
          <div className="name">
            <strong>
              Bose QuietComfort
              <br />
              Ultra
            </strong>{' '}
            Headphones
            <small>Noise cancelling, spatialised audio</small>
          </div>
        </div>
        <div className="value">valued at $649</div>
      </div>
      <div className="prize v--3rd">
        <div className="image">
          <img src="/assets/campaigns/ds24/prize_3rd.png" alt="" />
        </div>
        <div className="content">
          <small className="rank">3rd</small>
          <div className="name">
            <strong>Kobo Nia</strong>
            <br />
            eReader
            <small>8GB storage, 6" E Ink touchscreen</small>
          </div>
        </div>
        <div className="value">valued at $179</div>
      </div>
    </div>
  )
}

export default Prizes
