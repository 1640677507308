import React from 'react'

import { goToLandmark } from '../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'

import './BackToTop.sass'

const BackToTop = () => {
  return (
    <div className='backToTop fixed-bottom'><a href="#top" onClick={(evt) => {
      evt.preventDefault()
      goToLandmark('top')
    }}><FontAwesomeIcon icon={faAngleUp} /><small>Back to top</small></a></div>
  )
}

export default BackToTop
