import React from 'react'

import { goToLandmark } from '../../utils'

import './HowToPlay.sass'

const HowToPlay = () => {
  return (
    <section className="mb-5">
      <h2 className="sectionHeading v--subSection">How to play</h2>

      <ol className="howtoSteps mb-5">
        <li>
          <strong>Fill in the entry form</strong>
          <p>... and cross your fingers!</p>
          <p>
            <a
              className="btn btn-outline-light"
              href="#enter"
              onClick={(evt) => {
                evt.preventDefault()
                goToLandmark('enter')
              }}
            >
              Entry form
            </a>
          </p>
        </li>
        <li>
          <strong>Attend the prize draw</strong>
          <p>
            Be at the <strong>Ironstar booth</strong> at{' '}
            <strong>10:40am on Day 2</strong> of DrupalSouth 2024 to find out if
            you have won.
          </p>
        </li>
        <li className="no-counter aside">
          <strong className="text-primary">Don't be late!</strong> If your name
          is drawn and you aren't there to claim your prize, we'll re-draw until
          we can get rid of all this loot!
        </li>
      </ol>
    </section>
  )
}

export default HowToPlay
