import React from 'react'
import Helmet from 'react-helmet'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import './index.sass'

import PageFooter from '../../containers/PageFooter'

import Logo from '../../components/Logo'

import NavBtns from '../../components/DS24/NavBtns'
import CompMasthead from '../../components/DS24/CompMasthead'
import Prizes from '../../components/DS24/Prizes'
import CompEntry from '../../components/DS24/CompEntry'
import ActivityDetails from '../../components/DS24/ActivityDetails'
import Terms from '../../components/DS24/Terms'
import BackToTop from '../../components/BackToTop'

export default () => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_SITE_KEY}>
    <>
      <Helmet>
        <title>
          Ironstar at DrupalSouth 2023 | Secure, Stable, Sovereign Drupal
          Hosting
        </title>
        <meta
          name="description"
          content="Ironstar is an Australia owned and operated Drupal hosting provider, helping government and enterprise organisation secure and accelerate mission-critical Drupal websites."
        />
        <meta
          name="keywords"
          content="drupalsouth, drupal, hosting, ironstar, node.js, nodejs, laravel, secure, Australian-based"
        />
      </Helmet>

      <div className="headerContainer">
        <div id="top" className="logoContainer">
          <Logo />
          <div className="campaignDesc">
            at <strong>DrupalSouth 2024</strong> Sydney
          </div>
        </div>
        <NavBtns />
      </div>

      <main>
        <div id="competition" name="competition" className="px-3">
          <CompMasthead />
        </div>
        <div className="prizesSection pb-1">
          <div className="curveDivider"></div>
          <Prizes />
        </div>
        <CompEntry className={'entrySection'} />

        <ActivityDetails />
      </main>

      <div
        id="terms"
        name="terms"
        className="bg-light text-dark pt-4 pb-5"
        style={{ borderTop: '3px solid var(--bs__color__primary-main)' }}
      >
        <div className="container py-3">
          <Terms />
        </div>
      </div>

      <div className="bg-light py-4 text-muted small">
        <small className="container d-block">
          Photo by Drew Beamer on <i>Unsplash</i>
        </small>
      </div>

      <BackToTop />
      <PageFooter slim={true} />
    </>
  </GoogleReCaptchaProvider>
)
