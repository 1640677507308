import React from 'react'

import './CompEntry.sass'

import HowToPlay from './HowToPlay'
import EntryForm from './EntryForm'

const CompEntry = ({ className = 'entrySection' }) => {
  return (
    <div className={`${className} container`}>
      <div className="entryWrapper row">
        <div className="col-lg">
          <div id="howto" name="howto">
            <HowToPlay />
          </div>
        </div>
        <div className="col-lg">
          <div id="enter" name="enter">
            <EntryForm />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompEntry
